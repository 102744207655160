import { Box, Divider, Icon, Link, Stack, Text } from '@chakra-ui/react';
import { FaHeart } from 'react-icons/fa';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const Footer = () => (
  <Box as="footer" role="contentinfo" w="100%" py={4} px={4}>
    <Divider mb={4} />
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing="4"
      align="center"
      justify="space-between"
    >
      <Text fontSize="sm">
        Entwickelt mit{' '}
        <Icon
          as={FaHeart}
          style={{ transform: 'translate(-0.1em, 0.35em)' }}
          mx="3px"
          w={5}
          h={5}
          color="red.500"
        />
        von{' '}
        <Link href="https://wotschofsky.com" isExternal>
          Felix Wotschofsky <ExternalLinkIcon mx="2px" />
        </Link>
      </Text>
      <Text fontSize="sm">
        <Link href="https://royal-rangers-erlangen.de/impressum/" isExternal>
          Impressum & Datenschutz <ExternalLinkIcon mx="2px" />
        </Link>
      </Text>
    </Stack>
  </Box>
);

export default Footer;
