import type { AppProps } from 'next/app';
import Head from 'next/head';
import { chakra, Flex } from '@chakra-ui/react';
import { Global, css } from '@emotion/react';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import Footer from '@/components/layout/Footer';
import Header from '@/components/layout/Header';
import Providers from '@/components/misc/Providers';

const App = ({
  router,
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) => {
  NProgress.configure({
    showSpinner: false,
  });

  return (
    <Providers session={session}>
      <Global
        styles={css`
          html,
          body,
          #__next {
            height: 100%;
          }

          #nprogress .bar {
            background: #d41121 !important;
          }

          #nprogress .peg {
            box-shadow: 0 0 10px #d41121, 0 0 5px #d41121;
          }
        `}
      />

      {router.route === '/gesamtstatistik' ? (
        <Component {...pageProps} />
      ) : (
        <Flex height="100%" direction="column">
          <Header />
          <chakra.main flex="1" py={12} px={4}>
            <Head>
              <link rel="icon" href="/emblem.svg" />
            </Head>

            <Component {...pageProps} />
          </chakra.main>
          <Footer />
        </Flex>
      )}
    </Providers>
  );
};

export default App;
