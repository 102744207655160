import { ChakraProvider } from '@chakra-ui/react';
import { SessionProvider, type SessionProviderProps } from 'next-auth/react';
import { SWRConfig } from 'swr';
import PlausibleProvider from 'next-plausible';
import type { FC, ReactNode } from 'react';

type ProvidersProps = {
  session: SessionProviderProps['session'];
  children: ReactNode;
};

const Providers: FC<ProvidersProps> = ({ session, children }) => (
  <SessionProvider session={session}>
    <SWRConfig
      value={{
        refreshInterval: 60000,
        fetcher: (resource, init) =>
          fetch(resource, init).then((res) => res.json()),
      }}
    >
      <ChakraProvider>
        <PlausibleProvider
          customDomain="https://insights.wsky.dev"
          trackOutboundLinks
          domain="sponsorenlauf.rr-by2.de"
        >
          {children}
        </PlausibleProvider>
      </ChakraProvider>
    </SWRConfig>
  </SessionProvider>
);

export default Providers;
