import Link from 'next/link';
import { useSession, signOut } from 'next-auth/react';
import {
  Box,
  Button,
  chakra,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';

const Header = () => {
  const { data: session } = useSession();

  return (
    <Box px={4}>
      <Flex h={16} alignItems="center" justifyContent="space-between">
        <Link href="/">
          <HStack>
            <chakra.img src="/emblem.svg" alt="" height={8} />
            <Text fontSize="lg" cursor="pointer">
              Royal Rangers Sponsorenlauf
            </Text>
          </HStack>
        </Link>

        {session && (
          <Menu>
            <MenuButton as={Button} bg="none" rightIcon={<FiChevronDown />}>
              {session?.user?.email}
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => signOut()}>Abmelden</MenuItem>
            </MenuList>
          </Menu>
        )}
      </Flex>
    </Box>
  );
};

export default Header;
